.footer {
  text-align: center;
  padding: var(--gutter-lg) var(--gutter-sm);
}

.logo {
  width: 2.8125rem;
  margin-bottom: var(--gutter-md);
}

.description {
  font-size: var(--font-size-xs);
  color: var(--neutral-midtone);
  line-height: 1;
}
