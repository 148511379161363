.link {
  color: var(--primary-light);
  font-size: var(--font-size-sm);
  position: relative;
  text-decoration: underline;
  text-decoration-thickness: 0.125rem;
  text-underline-offset: 0.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  white-space: nowrap;

  &:hover {
    background-color: var(--primary-lightest);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.125rem var(--primary-lighter);
    border-radius: var(--border-radius-xxs);
    text-decoration: none;
  }

  &:active {
    background-color: var(--primary-light);
    color: #fff;
    border: none;
    border-radius: var(--border-radius-xxxs);
    text-decoration: none;
  }

  &:active svg path {
    fill: #fff;
  }

  &:active:focus {
    box-shadow: none;
  }
}

.link .startIcon,
.link .endIcon {
  height: 1.25rem;
}

.linkDisabled {
  color: var(--neutral-dark);
  cursor: default;
  pointer-events: none;
  text-decoration-color: var(--neutral-light);

  &:hover {
    background-color: unset;
  }
}

.linkDisabled svg path {
  fill: var(--neutral-dark);
}

.icon.link {
  min-width: 3.375rem;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-decoration: unset;
  background-color: transparent;
  border-radius: var(--border-radius-xs);
  padding: 0.25rem;
  font-weight: 700;

  &:hover {
    background-color: var(--primary-light);
  }

  &:focus {
    box-shadow: var(--button-hover-box-shadow);
  }

  &:active {
    box-shadow: 0 0 0 0.25rem var(--primary-light);
    background-color: var(--primary-midtone);
  }

  @media (min-width: 48rem) {
    min-width: 5rem;
    height: auto;
  }
}

.icon .startIcon {
  width: 1.75rem;
  height: 1.75rem;

  @media (min-width: 48rem) {
    width: 2.25rem;
    height: 2.25rem;
  }
}

.icon .startIcon svg {
  width: 100%;
  height: 100%;
}

.icon .startIcon svg path {
  fill: #fff;
}

.icon .children {
  font-size: var(--font-size-xxs);
  color: #fff;

  @media (min-width: 48rem) {
    font-size: var(--font-size-sm);
  }
}

.icon .endIcon {
  height: 0;
}
