.header {
  background: var(--primary-background-gradient);
  padding: var(--gutter-md) var(--gutter-sm);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (min-width: 30rem) {
    padding: var(--gutter-lg) var(--gutter-sm) var(--gutter-md);
  }
}

.title {
  text-transform: capitalize;
  margin-bottom: var(--gutter-xs);
  max-width: 37.5rem;
}

.shadow {
  text-shadow: var(--text-shadow);
}

.description {
  color: white;
  text-shadow: var(--text-shadow);
  font-size: var(--font-size-sm);
  max-width: 37.5rem;
  line-height: 1.2;

  @media (min-width: 21rem) {
    font-size: var(--font-size-md);
  }
}

.breadcrumb {
  margin-bottom: var(--gutter-xs);

  &:empty {
    display: none;
  }
}

.buttons {
  margin-top: var(--gutter-md);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.buttons > button,
.buttons > a {
  margin: 0 var(--gutter-xxxs) var(--gutter-xxs);
}
