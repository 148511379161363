.buttonLink {
  height: initial;
  width: fit-content;
  padding: var(--gutter-xxs) var(--gutter-sm);
  font-family: inherit;
  color: #fff;
  font-size: var(--font-size-sm);
  outline: none;
  border: none;
  border-radius: 3rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.5;

  &:focus {
    box-shadow: var(--button-focus-box-shadow);
  }
}

.startIcon,
.endIcon {
  width: 1.25rem;
  height: 1.25rem;
}

.startIcon {
  margin: 0 0.25rem 0 -0.5rem;
}

.endIcon {
  margin: 0 -0.5rem 0 0.25rem;
}

.primary {
  font-weight: 800;
  text-transform: uppercase;
  background-color: var(--primary-dark);
  letter-spacing: 0.063rem;

  svg path {
    fill: #fff;
  }

  &:hover {
    background: linear-gradient(180deg, #007abecc 0%, #007abe00 100%),
      var(--primary-dark);
    box-shadow: 0 0.125rem 0.5rem #05314966, 0 0.125rem 0.125rem #05314933;
  }

  &:active {
    color: var(--primary-lightest);
    box-shadow: inset 0 0.125rem 0.25rem #053149;
    border: unset;
  }
}

.primaryDisabled {
  cursor: default;
  pointer-events: none;
  font-weight: unset;
  color: var(--neutral-lighter);
  background-color: var(--neutral-dark);

  svg path {
    fill: var(--neutral-lighter);
  }

  &:hover {
    box-shadow: none;
    background: none;
    background-color: var(--neutral-dark);
  }
}

.secondary {
  color: var(--primary-dark);
  background-color: #fff;
  box-shadow: var(--primary-dark-box-shadow);

  &:hover {
    color: var(--primary-light);
    box-shadow: var(--primary-lighter-box-shadow);
    background-color: var(--primary-lightest);

    svg path {
      fill: var(--primary-light);
    }
  }

  &:active {
    color: #fff;
    background-color: var(--primary-light);
    box-shadow: inset 0 0.125rem 0.25rem var(--primary-dark);
    border: none;

    svg path {
      fill: #fff;
    }
  }
}

.secondaryDisabled {
  cursor: default;
  pointer-events: none;
  color: var(--neutral-dark);
  box-shadow: var(--neutral-light-box-shadow);
  background-color: var(--neutral-lighter);

  svg path {
    fill: var(--neutral-dark);
  }

  &:hover {
    color: var(--neutral-dark);
    box-shadow: var(--neutral-light-box-shadow);
    background-color: var(--neutral-lighter);

    svg path {
      fill: var(--neutral-dark);
    }
  }
}

.tertiary {
  color: var(--primary-dark);
  background-color: #fff;
  box-shadow: var(--button-box-shadow);

  em,
  strong {
    font-family: inherit;
    font-weight: 800;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 0.063rem;
  }

  svg {
    transform: scale(1.2);
    border-radius: 50%;
    background-color: var(--primary-dark);

    path {
      fill: #fff;
    }
  }

  .startIcon {
    margin: 0 0.5rem 0 -0.25rem;
  }

  .endIcon {
    margin: 0 -0.25rem 0 0.5rem;
  }

  &:hover {
    background-color: var(--primary-lightest);
    box-shadow: var(--button-hover-box-shadow);
  }

  &:active {
    color: #fff;
    background-color: var(--primary-lighter);
    box-shadow: inset 0 0.125rem 0.25rem #007abe99;
    border: none;

    svg path {
      fill: #fff;
    }
  }
}

.tertiaryDisabled {
  cursor: default;
  pointer-events: none;
  color: var(--neutral-dark);
  background-color: var(--neutral-lighter);
  box-shadow: var(--button-hover-box-shadow);

  svg {
    background-color: var(--neutral-dark);

    path {
      fill: #fff;
    }
  }

  &:hover {
    background-color: var(--neutral-lighter);
  }

  &:active {
    color: var(--neutral-dark);
    background-color: var(--neutral-lighter);
    box-shadow: var(--button-hover-box-shadow);

    svg {
      background-color: var(--neutral-dark);

      path {
        fill: #fff;
      }
    }
  }
}

.highlight {
  background-color: #fff;
  color: var(--secondary-midtone);
  box-shadow: 0 0 0 0.125rem var(--secondary-midtone) inset;

  &:hover {
    color: var(--secondary-light);
    box-shadow: var(--secondary-lighter-box-shadow);
    background-color: var(--secondary-lightest);

    svg path {
      fill: var(--secondary-light);
    }
  }

  &:active {
    color: #fff;
    background-color: var(--secondary-light);
    box-shadow: inset 0 0.125rem 0.25rem var(--secondary-dark);
    border: none;

    svg path {
      fill: #fff;
    }
  }

  &:focus {
    box-shadow: var(--button-highlight-focus-box-shadow);
  }
}

.breadcrumb {
  color: #fff;
  padding: var(--gutter-xxxs) var(--gutter-xs);
  font-size: var(--font-size-xxs);
  font-weight: 800;
  text-transform: uppercase;
  background-color: var(--primary-dark);
  letter-spacing: 0.063rem;

  svg {
    transform: scale(0.8);

    path {
      fill: #fff;
    }
  }

  &:hover {
    background-color: var(--primary-lighter);
  }

  &:active {
    background-color: var(--primary-light);
  }

  &:active:focus {
    box-shadow: none;
  }

  &:focus:hover {
    box-shadow: none;
  }
}

.breadcrumbDisabled {
  cursor: default;
  pointer-events: none;
  background-color: var(--neutral-midtone);

  svg {
    transform: scale(0.75);

    path {
      fill: #fff;
    }
  }

  &:hover {
    background-color: var(--neutral-midtone);
  }
}

.micro {
  color: var(--primary-dark);
  font-size: var(--font-size-xxs);
  font-weight: 800;
  letter-spacing: 0.063rem;
  text-transform: uppercase;
  background-color: #fff;
  box-shadow: var(--button-box-shadow);

  &:hover {
    background-color: var(--primary-lightest);
    box-shadow: var(--button-hover-box-shadow);
  }

  &:active {
    color: #fff;
    background-color: var(--primary-lighter);
    box-shadow: inset 0 0.125rem 0.25rem #007abe99;
    border: none;

    svg path {
      fill: #fff;
    }
  }
}

.microDisabled {
  cursor: default;
  pointer-events: none;
  color: var(--neutral-dark);
  background-color: var(--neutral-lighter);
  box-shadow: var(--button-hover-box-shadow);

  svg path {
    fill: var(--neutral-dark);
  }

  &:hover {
    background-color: var(--neutral-lighter);
  }

  &:active {
    color: var(--neutral-dark);
    background-color: var(--neutral-lighter);
    box-shadow: var(--button-hover-box-shadow);

    svg path {
      fill: var(--neutral-dark);
    }
  }
}

.microAlternate {
  color: var(--primary-dark);
  background-color: #fff;
  box-shadow: var(--primary-dark-box-shadow);

  &:hover {
    color: var(--primary-light);
    box-shadow: var(--primary-lighter-box-shadow);
    background-color: var(--primary-lightest);

    svg path {
      fill: var(--primary-light);
    }
  }

  &:active {
    color: #fff;
    background-color: var(--primary-lighter);
    box-shadow: inset 0 0.125rem 0.25rem #007abe99;
    border: none;

    svg path {
      fill: #fff;
    }
  }
}

.microAlternateDisabled {
  cursor: default;
  pointer-events: none;
  color: var(--neutral-dark);
  box-shadow: var(--neutral-light-box-shadow);
  background-color: var(--neutral-lighter);

  svg path {
    fill: var(--neutral-dark);
  }

  &:hover {
    color: var(--neutral-dark);
    box-shadow: var(--neutral-light-box-shadow);
    background-color: var(--neutral-lighter);

    svg path {
      fill: var(--neutral-dark);
    }
  }
}

.icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--gutter-xxxs) var(--gutter-xxs);
  font-size: var(--font-size-xxs);
  font-weight: 700;
  text-transform: lowercase;
  border-radius: var(--border-radius-xs);
  color: var(--primary-dark);
  background-color: #fff;
  line-height: 0.625rem;

  .startIcon {
    width: 100%;
    margin: 0 0 0.375rem;

    svg {
      width: 1.75rem;
      height: 1.75rem;
    }
  }

  &:hover {
    color: var(--primary-light);
    background-color: var(--primary-lightest);

    .startIcon svg path {
      fill: var(--primary-light);
    }
  }

  &:active {
    color: #fff;
    background-color: var(--primary-light);
    box-shadow: inset 0 2px 4px var(--primary-midtone);

    .startIcon svg path {
      fill: #fff;
    }
  }
}

.iconDisabled {
  cursor: default;
  pointer-events: none;
  color: var(--neutral-dark);
  background-color: var(--neutral-lighter);

  .startIcon svg path {
    fill: var(--neutral-dark);
  }

  &:hover {
    color: var(--neutral-dark);
    background-color: var(--neutral-lighter);

    .startIcon svg path {
      fill: var(--neutral-dark);
    }
  }

  &:active {
    box-shadow: none;
  }
}

.iconOnly {
  width: 2.75rem;
  height: 2.75rem;
  padding: var(--gutter-xxs);
  border-radius: 50%;

  .startIcon {
    margin: 0;
  }
}

.toast {
  font-size: var(--font-size-sm);
  color: var(--secondary-darker);
  font-weight: 700;
  background-color: var(--secondary-lightest);

  &:focus {
    box-shadow: inset 0 0 0 0.25rem var(--secondary-light);
  }

  &:hover {
    background-color: #fff;
  }

  &:active {
    color: #fff;
    background-color: var(--secondary-light);
  }

  @media (min-width: 48rem) {
    font-size: var(--font-size-md);
  }
}

.toastDisabled {
  cursor: default;
  pointer-events: none;
  color: #fff;
  background-color: var(--neutral-midtone);
}
